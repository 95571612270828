@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap");
@import "react-responsive-modal/styles.css";
@import "styles/colors.scss";
@import "styles/m-touch.scss";


@import "../components/ui/ui.scss";
@import "../components/button/button.scss";
@import "../components/popup/popup.scss";
@import "../components/side-nav/side-nav.scss";
@import "../components/nav-bar/nav-bar.scss";
@import "../components/alert/alert.scss";
@import "../components/footer/footer.scss";
@import "../components/pagination/pagination.scss";
@import "../pages/common/login/loginPage.scss";

* {
    box-sizing: border-box;
}

html {
    padding: 0;
    margin: 0;
    height: 100%;

    body {
        padding: 0;
        margin: 0;
        min-height: 100%;
        font-family: "Roboto", sans-serif;
        white-space: pre-wrap;
        background-color: $c-layout-bg;
    }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.rc-scrollbars-container {
    height: calc(100% - 100px) !important;
}
