.pagination {

    &__item {
        border-right: 2px solid #D2D2D2;
        padding: 0 12px;
        cursor: pointer;

        &:last-child {
            border: 0;
        }

        &--active {
            text-decoration: underline;
            color: $c-black;
            font-weight: 500;
        }
    }
}