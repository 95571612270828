.m-touch-input {
    background-color: $c-input-bg;

    &::placeholder {
        color: $c-input-placeholder;
    }
}

.m-search {

    input::placeholder {
        color: #888888;
    }
}
