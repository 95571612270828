.text {
	font-weight: 500;
	font-size: 16px;
}

.text-gray {
	color: #999999;
}

.text-danger {
	color: red;
}

.text-success {
	color: #31aa52;
}
