.nav-bar {
    width: 100%;
    padding: 11px 20px;
    background-color: $c-primary;

    .nav-container {
        width: 100%;
        min-height: 65px;
        // max-width: 1366px;
        margin: auto;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    &__action-wrapper {
        display: flex;
        align-items: center;
    }

    &__link {
        position: relative;
        padding: 0 15px;
        color: $c-white;
        outline: none;
        font-weight: 500;
        text-decoration: none;
        display: flex;
        justify-content: center;
        align-items: center;

        &--account {
            max-width: 200px;
            min-width: 170px;
            padding: 0 20px;

            span {
                margin: 0 8px;
            }
        }

        &--active {
            color: $c-gold;

            &:after {
                content: "";
                position: absolute;
                bottom: -7px;
                display: block;
                width: 100%;
                border-bottom: 2px solid $c-gold;
            }
        }
    }

    .divider {
        height: 20px;
        border-right: 1px solid rgba(255, 255, 255, 0.2);
        margin-left: 20px;
    }

    &__account-wrapper {
        position: relative;
        &:hover {
            .nav-bar__dropdown {
                visibility: visible;
                opacity: 1;
            }
        }
    }

    &__dropdown {
        position: absolute;
        width: 100%;
        top: 150%;
        box-shadow: $s-shadow;
        background-color: $c-white;
        z-index: 9999999999;
        visibility: hidden;
        transition: all 1s ease;
        opacity: 0;

        &:hover {
            .nav-bar__dropdown {
                visibility: visible;
                opacity: 1;
            }
        }

        ul {
            padding: 0;

            li {
                list-style-type: none;
                
                &:last-child {
                    border-top: 1px solid rgba($c-black, 0.1);
                }

                .nav-bar__link {
                    @extend .m-txt;
                    padding: 15px 10px;
                    font-weight: 400;
                    font-size: 12px;
                    display: flex;
                    align-items: center;
                    justify-content: flex-start;

                    span {
                        margin-left: 15px;
                    }

                    &:after {
                        display: none;
                    }

                    &:hover {
                        color: $c-dark-gold;
                    }

                    &--active {
                        color: $c-dark-gold;
                    }
                }
            }
        }
    }

    &__logo {
        height: 45px;
        min-width: 52px;
    }

    &__notification {
        position: relative;
        cursor: pointer;

        &:hover {
            .nav-bar__notification--dropdown {
                visibility: visible;
                opacity: 1;
            }
        }

        .redDot {
            width: 6px;
            height: 6px;
            position: absolute;
            top: 0;
            right: 0;
            background: red;
            border-radius: 50%;
        }

        &--dropdown {
            position: absolute;
            width: 380px;
            max-height: 480px;
            overflow-y: scroll;
            top: 170%;
            left: -200px;
            box-shadow: $s-shadow;
            background-color: $c-white;
            z-index: 9999999999;
            visibility: hidden;
            transition: all 0.3s ease;
            opacity: 0;
    
            &:hover {
                .nav-bar__dropdown {
                    visibility: visible;
                    opacity: 1;
                }
            }
    
            ul {
                padding: 0;
    
                li {
                    list-style-type: none;
                    
                    &:last-child {
                        border-top: 1px solid rgba($c-black, 0.1);
                    }
    
                    .nav-bar__link {
                        @extend .m-txt;
                        padding: 15px 10px;
                        font-weight: 400;
                        font-size: 12px;
                        display: flex;
                        align-items: center;
                        justify-content: flex-start;
    
                        span {
                            margin-left: 15px;
                        }
    
                        &:after {
                            display: none;
                        }
    
                        &:hover {
                            color: $c-dark-gold;
                        }
    
                        &--active {
                            color: $c-dark-gold;
                        }
                    }
                }
            }
        }

        &--item {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 20px;
            border-bottom: 1px solid rgba(0, 0, 0, 0.1);
        }

        &--read {
            background-color: #F8F8F8 !important;
        }

        &--date {
            display: flex;
        }

        .greenDot {
            background: $c-valid;
            width: 7px;
            height: 7px;
            border-radius: 50%;
            margin-top: -20px;
            position: absolute;
            right: 10px;
        }
    }
}
