.footer {
    // position: fixed;
    bottom: 0;
    width: 100%;
    padding: 0 25px;
    background-color: $c-layout-bg;

    &__section {
        padding: 25px 0;
    }
}
